<template>
  <div class="creator-become-steps">
    <div class="creator-steps">
      <CreatorBecomeStepsCard step="step1" :label="`${$locale.words['step']} 1`" icon="user" />
      <CreatorBecomeStepsCard step="step2" :label="`${$locale.words['step']} 2`" icon="file_certificate" />
      <CreatorBecomeStepsCard step="step3" :label="`${$locale.words['step']} 3`" icon="user_check" />
      <CreatorBecomeStepsCard step="step4" :label="`${$locale.words['step']} 4`" icon="mf_check" class="result" />
    </div>
    <div class="creator-steps-step">
      <transition name="fade-multiple">
        <CreatorBecomeSteps1 v-if="apply === 'step1'" />
        <CreatorBecomeSteps2 v-else-if="apply === 'step2'" />
        <CreatorBecomeSteps3 v-else-if="apply === 'step3'" />
        <CreatorBecomeSteps4 v-else-if="apply === 'step4'" />
      </transition>
    </div>
    <Spacer num="" />
  </div>
</template>

<script>
export default {
  components: {
    CreatorBecomeStepsCard: () => import("./CreatorBecomeStepsCard.vue"),
    CreatorBecomeSteps1: () => import("./CreatorBecomeSteps1.vue"),
    CreatorBecomeSteps2: () => import("./CreatorBecomeSteps2.vue"),
    CreatorBecomeSteps3: () => import("./CreatorBecomeSteps3.vue"),
    CreatorBecomeSteps4: () => import("./CreatorBecomeSteps4.vue"),
  },
  computed: {
    apply: function() {
      return this.$route.query.apply;
    },
  },
};
</script>
